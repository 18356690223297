import { FC } from 'react';
import Link from 'next/link';
import ContentCardImage from './ContentCardImage';

interface ContentCardLinkProps {
  path: string;
  text: string | JSX.Element;
  external: boolean;
}
interface ContentCardProps {
  title: string | JSX.Element;
  icon?: JSX.Element;
  image: string;
  link?: ContentCardLinkProps;
  wrapperClassName?: string;
}

const ContentCardLink: FC<ContentCardLinkProps> = ({
  path,
  text,
  external
}) => {
  const linkClassName =
    'block h-[48px] w-[260px] items-center justify-center rounded-[6px] bg-brand-black100 px-[30px] text-center font-medium leading-[44px] text-white transition-colors duration-200 hover:text-brand-orange focus:text-brand-grey450';

  return external ? (
    <a href={path} className={linkClassName}>
      {text}
    </a>
  ) : (
    <Link prefetch={false} href={path} className={linkClassName}>
      {text}
    </Link>
  );
};

const ContentCard: FC<ContentCardProps> = ({
  icon,
  title,
  image,
  children,
  link,
  wrapperClassName = ''
}) => {
  return (
    <div
      className={`items-center gap-[22px] shop:grid-cols-[1fr,266px] ${wrapperClassName}`}>
      {image && (
        <ContentCardImage image={image} icon={icon} title={title.toString()} />
      )}
      <div className="flex flex-col items-start justify-between">
        <span className="mb-[10px] text-[18px] font-semibold text-brand-black100 mt-[16px]">
          {title}
        </span>
        <div className="mb-[30px] w-full text-left text-[14px] font-medium leading-[16px] text-brand-grey300 sm:font-normal">
          {children}
        </div>
        {link && <ContentCardLink {...link} />}
      </div>
    </div>
  );
};

export default ContentCard;
