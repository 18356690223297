import { useEffect, useState } from 'react';

interface WindowSize {
  width: number;
  height: number;
  overThreshold: boolean | undefined;
}

function useWindowSize(threshold?: number): WindowSize {
  // Initialize state with undefined width/height
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: 0,
    height: 0,
    overThreshold: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    const handler = () => {
      // Get new window dimensions
      const newWidth = window.innerWidth;
      const newHeight = window.innerHeight;

      // Determine if over the threshold
      const isOverThreshold = threshold !== undefined ? newWidth > threshold : undefined;

      // Update state if there's no threshold or if the threshold state changes
      if (threshold === undefined || windowSize.overThreshold !== isOverThreshold) {
        setWindowSize({
          width: newWidth,
          height: newHeight,
          overThreshold: isOverThreshold,
        });
      }
    };

    // Add event listeners with { passive: true }
    window.addEventListener('resize', handler, { passive: true });
    window.addEventListener('orientationchange', handler, { passive: true });

    // Set the initial size
    handler();

    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('resize', handler);
      window.removeEventListener('orientationchange', handler);
    };
  }, [threshold, windowSize.overThreshold]); // Re-run the effect if the threshold or overThreshold changes

  return windowSize;
}

export default useWindowSize;
