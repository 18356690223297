import { SVGProps } from 'react';

const CrossMark = ({
  width = 19,
  height = 19,
  fill = '#2A2A2A',
  ...otherProps
}: SVGProps<SVGSVGElement>) => (
  <svg
    height={height + 'px'}
    width={width + 'px'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19 19"
    {...otherProps}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m18.039 1.77-.92-.918-8.174 8.174L.92 1 0 1.92l8.026 8.025L.151 17.82l.919.92 7.875-7.876 8.026 8.027.92-.92-8.027-8.026 8.175-8.174Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.0388 0.919239L17.1196 0L8.9452 8.1744L0.919239 0.14844L0 1.06768L8.02596 9.09364L0.150685 16.9689L1.06992 17.8881L8.9452 10.0129L16.9715 18.0391L17.8907 17.1199L9.86444 9.09364L18.0388 0.919239Z"
      fill={fill}
    />
  </svg>
);

export default CrossMark;
