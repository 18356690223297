import Image from "next/legacy/image";
import { DEFAULT_BLUR_IMAGE_DATA_URL } from 'constants/blurImage';
interface ContentCardImageProps {
  title: string;
  icon?: JSX.Element;
  image: string;
}
const ContentCardImage = ({ title, icon, image }: ContentCardImageProps) => (
  <div className="relative">
    {icon && (
      <div className="absolute left-[16px] top-[16px] z-[1] flex h-[58px] w-[58px] items-center justify-center rounded-[6px] bg-white">
        {icon}
      </div>
    )}
    <Image
      placeholder="blur"
      layout="responsive"
      width={423}
      height={205}
      className="rounded-[8px]"
      src={image}
      alt={title}
      blurDataURL={DEFAULT_BLUR_IMAGE_DATA_URL}
    />
  </div>
);

export default ContentCardImage;
