import { useMemo } from 'react';
import { useRouter } from 'next/router';
import ContentCard from 'components/ContentCard/ContentCard';
import EyeAlt from 'components/Icons/EyeAlt';
import PinAlt from 'components/Icons/PinAlt';
import LocationPicker, {
  LocationPickerCountry
} from 'components/LocationPicker/LocationPicker';
import { STORE_LOCATIONS } from 'data/store-locations';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

interface HomepageStoreContentProps {
  stores: any;
}

const LOCATION_PICKER_GROUP_BY_CITY_COUNTRIES = ['ca'];

const VISIBLE_LOCALES = ['ca', 'au'];

const HomepageStoreContent = ({ stores }: HomepageStoreContentProps) => {
  const router = useRouter();

  const isVisible = useMemo(
    () => VISIBLE_LOCALES.includes(router.locale as string),
    [router.locale]
  );
  /*
const countryList: LocationPickerCountry[] = [];

const findGroupPath = (name: string) => {
  const location = STORE_LOCATIONS.find(
    (sLocation) => sLocation.key.toLowerCase() === name.toLowerCase()
  );

  return location?.path ? '/stores/' + location.path : '/';
};

const storeList = Object.entries(stores).flatMap(
  ([, countryStates]: [any, any]) =>
    Object.entries(countryStates).flatMap(([, stores]) => stores)
);

storeList
  .filter((store: any) => store.status)
  .forEach((store: any) => {
    let country = countryList.find(
      (country: any) => country.code === store.location.country_short
    );

    if (!country) {
      country = {
        code: store.location.country_short,
        name: store.location.country,
        stateList: []
      };
      countryList.push(country);
    }

    const isGroupByCityCountry =
      LOCATION_PICKER_GROUP_BY_CITY_COUNTRIES.includes(
        country.code.toLowerCase()
      );

    let state = country.stateList.find(
      (state) =>
        state.code ===
        (isGroupByCityCountry
          ? store.location.city
          : store.location.state_short)
    );

    if (!state) {
      const groupCode = isGroupByCityCountry
        ? store.location.city
        : store.location.state_short;
      state = {
        code: groupCode,
        name: isGroupByCityCountry
          ? store.location.city
          : store.location.state,
        path: findGroupPath(groupCode) || '/',
        storeList: []
      };
      country.stateList.push(state);
    }
    state.storeList.push({
      name: store.title,
      path: '/stores/' + store.slug
    });
  });

countryList.sort((prev) =>
  prev.code.toLowerCase() === router.locale ? -1 : 1
);*/

  const appointmentCardTitle = useMemo(() => {
    switch (router.locale) {
      case 'ca':
        return 'Book an eye exam';

      case 'au':
      default:
        return 'Book an optometrist';
    }
  }, [router.locale]);

  const appointmentContentTitle = useMemo(() => {
    switch (router.locale) {
      case 'au':
        return 'Bulk billed eye tests';

      case 'ca':
      default:
        return 'Direct billed eye exams';
    }
  }, [router.locale]);
  const isLogin = useSelector((state: RootState) => state.authEvent.isLogin);

  const appointmentPath = useMemo(() => {
    switch (router.locale) {
      case 'ca':
        return {
          external: true,
          path: '/ca/book-an-eye-exam'
        };

      case 'au':
      default:
        return {
          external: true,
          path: '/au/book-an-optometrist'
        };
    }
  }, [router.locale]);

  const referralPath: any = useMemo(() => {
    let link = 'referral-program';
    if (isLogin) {
      link = 'referral';
    }

    switch (router.locale) {
      case 'ca':
        return {
          external: true,
          path: '/ca/' + link
        };

      case 'au':
      default:
        return {
          external: true,
          path: '/au/' + link
        };
    }
  }, [router.locale, isLogin]);

  const appointmentContentBody = useMemo(() => {
    switch (router.locale) {
      case 'ca':
        return (
          <div className='h-[48px]'>
            <p>We direct bill most insurers in Canada.</p>
            <p>
              Make an appointment with our friendly, expert optometrists today.
            </p>
          </div>
        );

      case 'au':
      default:
        return (
          <div className='h-[48px]'>
            <p>No out of pocket costs.   Make an appointment with</p>
            <p>
              our friendly, expert optometrists today.
            </p>
          </div>
        );
    }
  }, [router.locale]);

  return isVisible ? (
    <section className="bg-brand-grey250 py-[40px] px-4 sm:py-[88px]">
      <div className="mx-auto grid grid-cols-1 justify-between gap-[40px] md:grid-cols-3 lg:max-w-[1224px] lg:px-4">
        <div className="flex flex-col">
          <span className="mb-[24px] text-[24px] font-normal text-brand-black100">
            {appointmentCardTitle}
          </span>
          <ContentCard
            image="/images/homepage/booking.webp"
            title={appointmentContentTitle}
            wrapperClassName="flex-auto"
            link={{
              ...appointmentPath,
              text: 'Book Appointment'
            }}>
            {appointmentContentBody}
          </ContentCard>
        </div>
        <div className="flex flex-col">
          <span className="mb-[24px] text-[24px] font-normal text-brand-black100">
            Find a store
          </span>
          <ContentCard
            image="/images/homepage/store.webp"
            title="Find your local Dresden store"
            wrapperClassName="flex-auto"
            link={{
              path: '/stores',
              external: false,
              text: 'See All Locations'
            }}>
            <div className="max-w-auto sm:max-w-[256px]">
              <LocationPicker countryList={stores} />
            </div>
          </ContentCard>
        </div>

        <div className="flex flex-col">
          <span className="mb-[24px] text-[24px] font-normal text-brand-black100">
            More on Optics
          </span>
          <ContentCard
            image="/images/homepage/upclose-new.webp"
            title="Upclose with Dresden"
            wrapperClassName="flex-auto"
            link={{
              path: '/blog',
              external: false,
              text: 'Good Reads'
            }}>
            Got a burning question about eye care? Take a scroll through our blog page to learn about all things optics, glasses and contact lenses.
          </ContentCard>
        </div>
      </div>
    </section>
  ) : (
    <></>
  );
};

export default HomepageStoreContent;
